import { ChainLogo } from '@/components/common/ChainLogo';
import { CHAIN_ID_MAP, ChainIdString } from '@/utils/supportedChains';

export type ChainOption = {
  label: string;
  prefix: JSX.Element;
  id: ChainIdString;
  disabled: boolean;
  minimumPlanLvlRequired: number | null;
};

// This map is for type safety: it will not build if the any chain is not in the object
const chainOptionsMap: Record<ChainIdString, ChainOption> = {
  [CHAIN_ID_MAP.ethereum]: {
    label: 'Ethereum',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.ethereum} />,
    id: CHAIN_ID_MAP.ethereum,
    disabled: false,
    minimumPlanLvlRequired: null, // note - null means public(non logged in) users can use it as well
  },
  [CHAIN_ID_MAP.binance]: {
    label: 'Binance',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.binance} />,
    id: CHAIN_ID_MAP.binance,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.polygon]: {
    label: 'Polygon',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.polygon} />,
    id: CHAIN_ID_MAP.polygon,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.arbitrum]: {
    label: 'Arbitrum',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.arbitrum} />,
    id: CHAIN_ID_MAP.arbitrum,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.fantom]: {
    label: 'Fantom',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.fantom} />,
    id: CHAIN_ID_MAP.fantom,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.optimism]: {
    label: 'Optimism',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.optimism} />,
    id: CHAIN_ID_MAP.optimism,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.pulse]: {
    label: 'PulseChain',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.pulse} />,
    id: CHAIN_ID_MAP.pulse,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.avalanche]: {
    label: 'Avalanche',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.avalanche} />,
    id: CHAIN_ID_MAP.avalanche,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.base]: {
    label: 'Base',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.base} />,
    id: CHAIN_ID_MAP.base,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.linea]: {
    label: 'Linea',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.linea} />,
    id: CHAIN_ID_MAP.linea,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.solana]: {
    label: 'Solana',
    prefix: <ChainLogo chainId={CHAIN_ID_MAP.solana} />,
    id: CHAIN_ID_MAP.solana,
    disabled: false,
    minimumPlanLvlRequired: 40,
  },
};
export const chainOptions = Object.values(chainOptionsMap);

export const SUPPORTED_CHAIN_IDS = [1, 56, 137, 43114, 250, 100, 10, 8453, 324, 42161];
export const supportedTradeChainOptions = chainOptions.filter((option) => {
  if (option.id == CHAIN_ID_MAP.solana) return false;
  return SUPPORTED_CHAIN_IDS.includes(parseInt(option.id));
});
